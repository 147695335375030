<script>
  import Player from '@vimeo/player';

  export let clip;

  let player;

  function onPlay() {
    localStorage.setItem('recentClipId', clip.url.split('.com/')[1]);
  }

  function attachPlayer(node, clip) {
    player = new Player(node, {
      id: +clip.url.split('.com/')[1],
    });

    player.on('play', onPlay);

    return {
      update(newClip) {
        player.getPaused().then((paused) => {
          player
            .loadVideo(+newClip.url.split('.com/')[1])
            .then(() => {})
            .catch(console.error);
        });
      },
      destroy() {
        player.off('play');
      },
    };
  }
</script>

<style>
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container :global(iframe),
  .embed-container :global(object),
  .embed-container :global(embed) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }</style>

<div class="z-10 rounded-lg wrapper embed-container" use:attachPlayer={clip} />
