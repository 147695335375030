<script>
  import Loader from './Loader.svelte';
  import {
    emailValidator,
    requiredValidator,
  } from '../services/validation/validators';
  import { createFieldValidator } from '../services/validation/validation';

  import { auth } from '../services/firebase';

  // Debounce
  const debounce = (callback, milliSeconds) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback();
      }, milliSeconds);
    };
  };

  const [validity, validate] = createFieldValidator(
    requiredValidator(),
    emailValidator()
  );

  let email = null;
  let password = null;
  let prompt = '';
  let needPassword = false;
  let showSubmitBtn = true;
  function signIn() {
    if (!password) {
      needPassword = true;
      document.querySelector('#password').select();
      return;
    }
    showSubmitBtn = false;

    const promise = auth.signInWithEmailAndPassword(email, password);
    promise.catch((err) => {
      prompt = err.message;
      showSubmitBtn = true;
    });
  }

  let showResetEmailInput = false;
  let userToReset = '';

  function handleResetPassword() {
    if (!userToReset) return;

    auth.sendPasswordResetEmail(userToReset).then(() => {
      showResetEmailInput = !showResetEmailInput;
      alert('ภายใน 3 นาที คุณจะได้รับอีเมล ที่มีลิ้งสำหรับตั้งรหัสผ่านใหม่');
    }, console.error);
  }
</script>

<style>
  #submit-btn:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  #submit-btn:active {
    background-color: white;
  }
  button:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }</style>

<div
  class="flex flex-col justify-center min-h-screen py-12 -mt-12 bg-black md:mt-0 sm:px-6 lg:px-8">
  <div class="flex justify-center sm:mx-auto sm:w-full sm:max-w-md">
    <div class="w-40 pt-12">
      <img src="images/admissionhack-logo-wh.png" alt="AdmissionHack Logo" />
    </div>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
    <div class="px-4 py-8 sm:px-10">
      <form on:submit|preventDefault={signIn}>
        <div>
          <label
            for="email"
            class="block text-lg font-medium leading-5 text-gray-200">
            Username
          </label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              id="email"
              type="email"
              bind:value={email}
              use:validate={email}
              required
              class="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none md:text-base focus:outline-none focus:shadow-outline-gray focus:border-gray-300 sm:text-sm sm:leading-5" />
            {#if email && !$validity.valid}
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  class="w-5 h-5 text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            {:else if email && $validity.valid}
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            {/if}
          </div>
        </div>

        <div class="mt-6">
          <label
            for="password"
            class="block text-lg font-medium leading-5 text-gray-200">
            Password
          </label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              id="password"
              type="password"
              bind:value={password}
              class="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none md:text-base focus:outline-none focus:shadow-outline-gray focus:border-gray-300 sm:text-sm sm:leading-5" />
            {#if email && $validity.valid && needPassword}
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  class="w-5 h-5 text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            {/if}
          </div>
        </div>

        <div class="flex flex-col mt-6">
          {#if showSubmitBtn}
            <span class="block w-full rounded-md shadow-sm">
              <button
                id="submit-btn"
                type="submit"
                class="flex justify-center w-full px-4 py-2 text-lg font-medium text-gray-900 transition duration-150 ease-in-out border border-transparent rounded-md bg-gray-50 focus:outline-none focus:shadow-outline-gray"
                disabled={!$validity.valid}>
                เข้าสู่ระบบ
              </button>
            </span>
          {:else}
            <div class="flex justify-center">
              <Loader />
            </div>
          {/if}
          {#if prompt}
            <span class="mt-4 text-sm font-normal text-gray-400">{prompt}</span>
          {/if}
        </div>
      </form>
      <div class="mt-10">
        <span
          on:click={() => (showResetEmailInput = !showResetEmailInput)}
          class="mt-10 mb-2 cursor-pointer text-gray-50 hover:text-gray-300">กดที่นี่เพื่อ
          reset รหัสผ่านของคุณ</span>
        <div class={showResetEmailInput ? 'block' : 'hidden'}>
          <input
            bind:value={userToReset}
            placeholder="กรอก username ของคุณ"
            class="block w-full px-3 py-2 mt-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none md:text-base focus:outline-none focus:shadow-outline-gray focus:border-gray-300 sm:text-sm sm:leading-5" />
          <button
            on:click={handleResetPassword}
            id="submit-btn"
            class="flex justify-center w-full px-4 py-2 mt-3 text-lg font-medium text-gray-900 transition duration-150 ease-in-out border border-transparent rounded-md bg-gray-50 focus:outline-none focus:shadow-outline-gray">
            รับอีเมลสำหรับตั้งรหัสใหม่
          </button>
        </div>
      </div>

      <div class="mt-10">
        <p class="text-lg text-gray-200">
          สอบถามติดต่อ Line ID: @admissionhack
        </p>
      </div>
    </div>
  </div>
</div>
